import Config from '../core/Config';

const { actionTrigger } = Config.get();

export const get = async (url, opts = {}) => {
    const { hostname, port, protocol } = window.location;

    if (url.startsWith('/')) {
        // eslint-disable-next-line no-param-reassign
        url = `${protocol}//${hostname}${url}`;
    }

    if (port) {
        // eslint-disable-next-line no-param-reassign
        url = new URL(url);
        url.port = port;
        // eslint-disable-next-line no-param-reassign
        url = url.toString();
    }

    return fetch(url, {
        method: 'GET',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json'
        },
        referrerPolicy: 'same-origin',
        ...opts
    });
};

export const post = async (url, opts = {}) => {
    let { csrfTokenValue } = Config.get();
    if (!csrfTokenValue) {
        csrfTokenValue = await get(`/${actionTrigger}/taxifix/csrf`)
            .then(res => res.json())
            .then(({ token }) => {
                Config.set('csrfTokenValue', token);
                return token;
            })
            .catch(error => {
                console.error(error);
            });
    }
    const { headers = {} } = opts;
    if (opts.body && !(opts.body instanceof FormData)) {
        opts.body = JSON.stringify(opts.body);
        headers['Content-Type'] = 'application/json';
    }

    const { hostname, port, protocol } = window.location;

    if (url.startsWith('/')) {
        // eslint-disable-next-line no-param-reassign
        url = `${protocol}//${hostname}${url}`;
    }

    if (port) {
        // eslint-disable-next-line no-param-reassign
        url = new URL(url);
        url.port = port;
        // eslint-disable-next-line no-param-reassign
        url = url.toString();
    }

    return fetch(url, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
            'X-CSRF-Token': csrfTokenValue,
            ...headers
        },
        referrerPolicy: 'same-origin',
        ...opts
    });
};
