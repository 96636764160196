import $ from '../core/Dom';
import { post } from '../lib/ajax';

export default (form, options) => {
    const $form = $(form);
    const errorMessage = $form.find('[data-error]').get(0);
    const $receipt = $form.find('[data-form-receipt]');
    const $receiptBtn = $form.find('[data-form-receipt-button]');
    const $content = $form.find('[data-form-content]');
    const submitBtn = $form.find('button[type="submit"]').get(0);

    const announcer = $form.find('[data-announcer]').get(0);
    const messages = announcer ? JSON.parse(announcer.dataset.messages) : [];

    const { onSuccess } = options || {};

    let isSubmitting = false;

    const disableSubmit = () => {
        submitBtn.setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        submitBtn.removeAttribute('aria-disabled');
    };

    // Clear input fields
    const clearInputs = () => {
        $form.find('input:not([type="hidden"])').get().forEach(input => {
            input.value = null;

            try {
                input.dispatchEvent(new Event('change'));
            } catch (error) {
                console.error(error);
            }
        });
    };

    const showGenericError = message => {
        console.log('showGenericError');
        errorMessage.hidden = false;
        errorMessage.innerHTML = message;
        errorMessage.focus = true;
    };

    const clearErrors = () => {
        if (errorMessage) {
            errorMessage.hidden = true;
            errorMessage.innerHTML = '';
        }
        $form.find('[data-field-error-message').each(node => {
            node.innerHTML = '';
            node.hidden = true;
        });
        $form.find('[data-input]').removeClass('has-error');
    };

    const showErrors = (errors = []) => {
        console.log(errors);
        clearErrors();
        
        Object.entries(errors).forEach(entry => {
            const [key, value] = entry;
            const $field = $form.find('input[name="' + key + '"]'); 
            const $fieldError = $form.find('[data-field-error-message="' + key + '"]');
            
            $field.addClass('has-error');
            $fieldError.text(value);
            $fieldError.get(0).hidden = false;
        });

        enableSubmit();

    };

    const hideReceipt = () => {
        console.log('hideReceipt');
        $receipt.get(0).hidden = true;
        $content.get(0).hidden = false;
    };

    const showReceipt = () => {
        console.log('showReceipt');

        $receipt.get(0).hidden = false;
        $content.get(0).hidden = true;
    };

    const onSubmit = async e => {
        e.preventDefault();

        if (isSubmitting || submitBtn.hasAttribute('aria-disabled')) {
            return;
        }

        isSubmitting = true;
        disableSubmit();

        submitBtn.classList.add('loading');

        if (announcer) {
            announcer.textContent = messages.submitting || '';
        }

        const url = window.location.href.split('?')[0].split('#')[0];

        const response = await post(url, { body: new FormData($form.get(0)) });

        const { status } = response;

        response.json()
            .then(res => {
                clearErrors();
                if (status < 400) {
                    if (announcer) {
                        announcer.textContent = messages.submitted || '';
                    }
                    clearInputs();
                    if (onSuccess) {
                        onSuccess(res);
                        return;
                    }
                    const { redirect } = res;
                    if (redirect) {
                        if (redirect.startsWith('http')) {
                            window.location.href = res.redirect;
                        } else {
                            window.location.href = `/${res.redirect}`;
                        }
                    } else {
                        showReceipt();
                    }
                } else if (res.errors) {
                    if (announcer) {
                        announcer.textContent = messages.errors || '';
                    }
                    showErrors(res.errors);
                } else {
                    if (announcer) {
                        announcer.textContent = messages.failure || '';
                    }
                    showGenericError(res.message || 'En feil oppstod. Vennligst prøv igjen.');
                }
            })
            .finally(() => {
                isSubmitting = false;
                submitBtn.classList.remove('loading');
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        $form.find('a[href]:not([href^="#"])').each(link => {
            $(link).attr({
                target: '_blank',
                rel: 'noopener noreferrer'
            });
        });

        $receiptBtn.on('click', e => {
            e.preventDefault();
            hideReceipt();
        });
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    init();

    return {
        destroy
    };
};
